import {Components} from "eyecons-components";
import {Container, ChannelFollow, ChannelLogos} from 'src/components'
import {Channel, usePlans} from 'eyecons-requests'
import classNames from "classnames";

type ChannelHeroProps = {
    channel: Channel
}

export function ChannelHero({channel}: ChannelHeroProps) {
    const plans = usePlans({channel_id: channel.id, hidden: false})

    return (
        <>
            <div
                className={classNames('relative z-40 bg-cover bg-center bg-no-repeat flex flex-col justify-evenly bg-dark', {
                    'pb-10 pt-10 md:pt-36 md:pb-32': channel.type.name === 'club',
                    'pb-6 pt-32 md:pb-10': channel.type.name === 'competition',
                })}
                style={{backgroundImage: `url(${channel.banner})`}}
            >
                <div className={'absolute inset-0 z-10 bg-gradient-to-l from-[#0e0b3b00] to-[#0e0b3b] bg-no-repeat'}/>
                <Container className={"storybook-channels-hero-channel"}>
                    <figure>
                        <img src={channel.logo} alt={channel.label}/>
                    </figure>
                    <div>
                        <h1>{channel.label}</h1>
                        <span>{channel.videos_count}{" "}{channel.videos_count === 1 ? "video" : "video's"}</span>
                    </div>
                    <div className={"storybook-channels-hero-buttons"}>
                        <ChannelFollow channel={channel}/>
                    </div>
                </Container>
                {channel.teams_count > 0 && channel.type.name === "competition" && (
                    <Container className="z-20">
                        <ChannelLogos channel={channel}/>
                    </Container>
                )}
            </div>

            {
                Boolean(plans.data.length) &&
                <Container className={'relative md:-mt-12 lg:-mt-20 px-4 z-50 mb-8'}>
                    <Components.Subscriptions.Cards plans={plans.data}/>
                </Container>
            }


            {channel.type.name === "club" && (
                <div>
                    <Components.Events.Calender
                        queryParams={{
                            channel_id: channel.id ?? undefined,
                            teams: channel.teams ?? undefined,
                        }}
                    />
                </div>
            )}
        </>
    );
}
