import React, {useState} from 'react'
import {Components, Functions} from "eyecons-components";
import {Request} from 'eyecons-requests'
import {SetPage} from "src/components";


type PasswordResetFormProps = {
    locale: string,
    setPage?: SetPage
}

export function PasswordResetForm({locale, setPage}: PasswordResetFormProps) {
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState([])
    const [passwordReset, setPasswordReset] = useState<any>({
        email: '',
        locale
    })


    function submitPasswordReset() {
        Request.fromApi('auth')
            .withErrors(setErrors)
            .post('/forgot-password', passwordReset)
            .then((response: any) => {
                if (response.data) {
                    setSuccess(true)
                }
            })
    }

    if (success) {
        return (
            <div className={'relative text-center'}>
                <Components.Texts.Heading type={'h5'} appendClassname={'text-dark mb-4'}>
                    {Functions.translate('Check your mailbox', locale)}
                </Components.Texts.Heading>

                <Components.Texts.Primary className={'text-gray-500'}>
                    {Functions.translate('We have sent you a link to reset your password.', locale)}
                </Components.Texts.Primary>
            </div>
        )
    }

    return <>
        <div className={'text-center mb-4'}>
            <Components.Texts.Heading type={'h5'} appendClassname={'text-dark mb-4'}>
                {Functions.translate('Reset password', locale)}
            </Components.Texts.Heading>

            <Components.Texts.Primary className={'text-gray-400'}>Voer je e-mailadres in om je wachtwoord te
                herstellen:</Components.Texts.Primary>
        </div>

        <Components.Texts.Primary appendClassname={'mb-2 text-center'}>
            {Functions.translate('No account?', locale)}
            {' '}
            {
                setPage && <button className={'text-blue-200'} onClick={() => setPage({
                    page: 'register',
                    locale
                })}>{Functions.translate('Register')}</button>
            }
            {
                !setPage && <Components.Texts.Link href={'/register'}>
                    {Functions.translate('Register')}
                </Components.Texts.Link>
            }
        </Components.Texts.Primary>

        <Components.Forms.Field>
            <Components.Forms.Input
                placeholder={Functions.translate('E-mail address', locale)}
                type={'email'}
                onChange={(email: string) => setPasswordReset({...passwordReset, email})}
                value={passwordReset.email}
            />
        </Components.Forms.Field>


        <Components.Forms.Buttons
            type={'wide'}
            errors={errors}
            title={Functions.translate('Reset password', locale)}
            submit={submitPasswordReset}
        />
    </>
}