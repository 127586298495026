import React from 'react'
import {Components} from 'eyecons-components'
import {Container} from "src/components";
import {Channel, useEvents} from 'eyecons-requests';

type ChannelEventsProps = {
    channel: Channel
}

export function ChannelEvents({channel}: ChannelEventsProps) {
    const events = useEvents({
        channel_id: channel.id,
        published: true,
        paginate: 4,
        with_live_videos: true,
        teams: channel.teams,
    }, true)

    if (!events.data.length) {
        return null
    }

    return <Container>
        <div className={'gap-2 sm:grid sm:gap-4 sm:grid-cols-2'}>
            {
                events.data.map((event, index) => {
                    return <Components.Videos.CardLive key={index} video={event}/>
                })
            }
        </div>
    </Container>
}

