import classNames from "classnames";

type ContainerProps = {
    children: React.ReactNode, className?: string,
    size?: 'small' | 'medium' |  'normal' | 'wide'
    withVerticalPadding?: boolean
}

export function Container({children, className, size = 'wide', withVerticalPadding = true}: ContainerProps) {
    return (
       <div className={classNames('px-4 mx-auto', {
           'max-w-md' : size === 'small',
           'max-w-2xl': size === 'medium',
           'max-w-3xl': size === 'normal',
           'container' : size === 'wide',
           'py-8': withVerticalPadding,
       }, className)}>
           {children}
       </div>
    )
}
