import { createContext } from 'react'
import * as Requests from 'eyecons-requests'

type VideoContextType = {
    video: Requests.Video
}

export const VideoContext = createContext<VideoContextType>({
    video: {
    }
} as VideoContextType)
