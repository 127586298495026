import React, {useContext} from 'react'
import {VideoContext} from "src/layouts";
import {PlayerShare} from "src/components";
import {Components} from "eyecons-components";

type PlayerVodProps = {
    autoplay?: boolean,
    playable?: boolean
}

export function PlayerVod({autoplay = false, playable = false}: PlayerVodProps) {
    const {video} = useContext(VideoContext)

    if (!playable) {
        return (
            <img src={video.thumbnail_large} className={'object-cover'} alt=""/>
        )
    }

    return <div className={'relative'}>
        <Components.Players.Player
            video={video}
            autoplay={autoplay}
            playable={playable}
        />

        <PlayerShare video={video}/>
    </div>
}