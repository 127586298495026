import { createContext } from 'react'
import * as Requests from 'eyecons-requests'

type AppContextType = {
    authenticated: Requests.Authenticated|null
    authenticatedIsLoading: boolean
    liveCount: number
    mutateAuthenticated: () => void
    searching: boolean
    setSearching: (searching: boolean) => void
}

export const AppContext = createContext<AppContextType>({} as AppContextType)
