import React, {useContext, useState} from "react";
import {Components} from 'eyecons-components'
import {VideoContext} from "src/layouts";
import {Request} from 'eyecons-requests'
import {useRouter} from "next/router";

export function PlayerVoucherWindow({setPaid}: {setPaid: (paid: boolean) => void}) {
    const router = useRouter()
    const {video} = useContext(VideoContext)
    const [errors, setErrors] = useState<Array<any>>([])

    function submitVoucher() {
        Request.fromApi('ecommerce')
            .withErrors(setErrors)
            .post('/redeems', {
                hash: router.query.voucher,
                external_id: video.event_id,
                external_type: 'events',
            })
            .then(response => {
                if (response.data) {
                    setPaid(Boolean(response.data.id))
                }
            })
    }

    return (
        <div
            className={'test-voucher absolute z-[70] inset-0 bg-gray-900/60 backdrop-blur-sm flex flex-col justify-center items-center gap-10'}>
            <div className={'flex flex-col gap-2 w-full text-center'}>
                <div className={'mb-2'}>
                    {
                        <Components.Buttons.Button type={'primary'} onClick={submitVoucher}>
                            Voucher valideren
                        </Components.Buttons.Button>
                    }
                </div>

                <Components.Texts.Small className={'font-bold text-white block'}>
                    Door op Voucher valideren te klikken wissel je jouw voucher in voor een gratis dagticket.
                </Components.Texts.Small>


                <div className={'max-w-sm mx-auto'}>
                    {Boolean(errors.length) && <Components.Forms.Errors errors={errors}/>}
                </div>
            </div>
        </div>
    )

}