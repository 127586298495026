import React, {useContext, useState} from "react";
import {PlayerModal, PaymentForm} from 'src/components'
import {Components, Functions} from 'eyecons-components'
import {VideoContext} from "src/layouts";

export function PlayerPaymentWindow() {
    const {video} = useContext(VideoContext)
    const [open, setOpen] = useState(false)

    if (!open) {
        const searchKey = video.type == 'live' ? 'event_id' : 'video_id'

        return (
            <div className={'absolute z-20 inset-0 bg-gray-900/60 backdrop-blur-sm flex flex-col justify-center items-center gap-10'}>
                <Components.Texts.Primary className={'font-bold text-white block'}>
                    Kies een van de volgende opties om te kijken:
                </Components.Texts.Primary>

                <div className={'flex flex-col gap-2 w-full max-w-xs'}>
                    {
                        video?.price?.enabled && <Components.Buttons.Button type={'primary'} onClick={() => setOpen(true)}>
                            Betaal eenmalig {Functions.formatPrice(video.price.amount, 'EUR', 'nl-NL')}
                        </Components.Buttons.Button>
                    }

                    {
                        video.has_public_plans &&
                        <Components.Buttons.Link
                            type={'primary'}
                            to={`/subscriptions?${searchKey}=${video.id}`}
                            target={Functions.inIframe() ? '_blank' : ''}
                        >
                            Kies een abonnement
                        </Components.Buttons.Link>}
                </div>
            </div>
        )
    }

    return <PlayerModal onClose={() => setOpen(false)}>
        <PaymentForm video={video}/>
    </PlayerModal>
}