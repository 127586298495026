import React from 'react'
import {Components, Hooks} from 'eyecons-components'
import classNames from "classnames";
import {Event} from 'eyecons-requests'

type EventList = {
    withAd?: boolean
    darkBackground?: boolean,
    events: Array<Event>
}
export function EventList({ darkBackground, events = [], withAd }: EventList) {
    const [Scroll] = Hooks.useScroll('live-streams')

    if (!events.length) {
        return null
    }

    return (
        <div className={classNames('mb-6', darkBackground && 'bg-purple-500 py-10')}>
            <div className={'mb-6 flex gap-8 items-center px-4 xl:px-12'}>
                <Components.Texts.Heading type="h4" className={'text-white font-bold'}>
                    NU LIVE
                </Components.Texts.Heading>
                <a href="/live" className={'text-secondary-500 font-bold'}>
                    ALLE LIVESTREAMS
                </a>
            </div>

            <Scroll variant="big" contentClassName={'mb-8 relative w-full flex gap-5'}>
                {events.map((event, index) => {
                    return (
                        <Components.Channels.VideoCard
                            video={event}
                            key={index}
                            index={index}
                            variant="live"
                            isLive
                        />
                    )
                })}
            </Scroll>

            {withAd && (
                <Components.Ads.Ad className={'leaderboard-homepage'} type={'leaderboard-billboard'} />
            )}
        </div>
    )
}
