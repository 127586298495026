import Link from "next/link";
import {Components} from "eyecons-components";
import React from "react";

type VideoChannelProps = {
    icon: string,
    label: string
    name: string
    right?: boolean
    videos_count?: number
}

export function VideoChannel({icon, label, name, right, videos_count}: VideoChannelProps) {
    function Logo() {
        return (
            <img
                src={icon}
                className={'rounded-full w-10 h-10 sm:w-16 sm:h-16 object-cover'}
                alt={label}
            />
        )
    }

    return (
        <Link href={`/channels/${name}`}>
            <div className={'flex items-center gap-2 sm:gap-3'}>
                {!right && <Logo/>}

                <div>
                    <Components.Texts.Heading type={'h6'}>
                        {label}
                    </Components.Texts.Heading>
                    {
                        Boolean(videos_count) && <Components.Texts.Primary className={'text-gray-400'}>
                            {videos_count} {videos_count === 1 ? 'video' : `video's`}
                        </Components.Texts.Primary>
                    }
                </div>

                {right && <Logo/>}
            </div>
        </Link>
    )
}
