import React, { useState } from 'react'
import { Components} from "eyecons-components";
import classNames from "classnames";
import * as Requests from "eyecons-requests";

type CalendarProps = {
    sport?: Requests.Sport
    sports?: Array<Requests.Sport>
    contained?: boolean
}

export function EventCalendar({ sport, sports = [], contained }: CalendarProps) {
    let initialQueryParams: any = {
        limit: 31
    }

    if (sport) {
        initialQueryParams.sport_id = sport.id
    }

    const [queryParams, setQueryParams] = useState(initialQueryParams)

    const alphabetizedSports = [...sports].sort((a, b) => a.label.localeCompare(b.label))

    return (
        <div className="w-full bg-purple-500 py-10 lg:py-16 mb-10 lg:mb-16">
            <div
                className={classNames(
                    'w-full flex flex-col px-4',
                    {
                        'container mx-auto' : contained,
                        'lg:px-12': !contained
                    }
                )}
            >
                <div className="flex justify-start items-center space-x-4 mb-8">
                    <Components.Texts.Heading type="h4" className={'text-white font-bold'}>
                        Programma
                    </Components.Texts.Heading>
                    {sport ? (
                        <div className="text-secondary-500 uppercase relative top-1">
                            {sport.label}
                        </div>
                    ) : (
                        <Components.Forms.Select
                            onChange={(sport_id: string) => {
                                if(sport_id === '0') {
                                    let newQueryParams = {...queryParams}
                                    delete newQueryParams.sport_id
                                    setQueryParams(newQueryParams)
                                    return
                                }

                                setQueryParams({...queryParams, sport_id})
                            }}
                            value={queryParams.sport_id || ''}
                            variant="unstyled"
                            className="flex items-center justify-between bg-transparent text-secondary-500 relative lg:top-1 uppercase"
                        >
                            <option value="0">Alle sporten</option>
                            {alphabetizedSports?.map((sport, i) => (
                                <option key={i} value={sport.id}>
                                    {sport.label}
                                </option>
                            ))}
                        </Components.Forms.Select>
                    )}
                </div>
            </div>

            <div className={classNames('w-full', contained && 'container px-4 mx-auto')}>
                <Components.Events.Calender
                    variant="white"
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                />
            </div>
        </div>
    )
}
