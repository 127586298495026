import React from 'react'
import { VideoContext } from 'src/layouts'
import * as Requests from "eyecons-requests";

type VideoProps = {
    children: React.ReactNode
    video: Requests.Video
}

export function Video({ video, children }: VideoProps) {
    return (
        <VideoContext.Provider
            value={{video}}
        >
            {children}
        </VideoContext.Provider>
    )
}
