import * as App from 'firebase/app';
import * as Database from 'firebase/database';

import {Authenticated} from 'eyecons-requests'

export default class Firebase {
    private app: App.FirebaseApp;
    private database: Database.Database;

    constructor() {
        this.app = App.initializeApp({
            projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
            appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID!,
            databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL!,
            storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET!,
            apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY!,
            authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN!,
            messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID!,
        });
        this.database = Database.getDatabase(this.app);
    }

    index(table: string, setRecords: (data: any[]) => void): void {
        const tableRef = Database.ref(this.database, table);

        Database.onValue(
            Database.query(
                tableRef,
                Database.limitToLast(10),
                Database.orderByChild('time'),
                Database.startAfter(Date.now() - 4 * 3600 * 1000)
            ),
            (snapshot) => {
                const messages = snapshot.val();
                const data: any[] = [];

                if (messages) {
                    Object.keys(messages).forEach((key) => {
                        data.push(messages[key]);
                    });
                }

                if (data.length) {
                    setRecords(data);
                }
            }
        );
    }

    store(table: string, authenticated: Authenticated, data: Record<string, any>): void {
        const key = Database.push(Database.ref(this.database, table)).key;

        data = {
            key,
            user_id: authenticated.id,
            username: authenticated.name,
            time: Date.now(),
            ...data,
        };

        console.log(data)

        Database.set(Database.ref(this.database, `${table}/${key}`), data).then(() => {});
    }

    remove(table: string): void {
        Database.remove(Database.ref(this.database, table)).then(() => {});
    }

    off(table: string): void {
        Database.off(Database.ref(this.database, table));
    }
}
