import React, {useState} from 'react'
import {Components, Functions} from 'eyecons-components'
import {useRouter} from "next/router";
import {Request, Voucher, Video} from 'eyecons-requests'

export function PaymentForm({video, simple = false}: { video: Video, simple?: boolean }) {
    const router = useRouter()
    const [voucherCode, setVoucherCode] = useState<string>('')
    const [voucher, setVoucher] = useState<Voucher | null>(null)
    const [paying, setPaying] = useState(false)
    const [errors, setErrors] = useState([])
    const orderable_id = video.has_event ? video.event_id : video.id

    function submitOrder() {
        let options = Functions.inIframe() ? {page: router.query.parent || Functions.pageUrl()} : {}

        const data: any = {
            price: video?.price?.amount,
            description: `${video.channel.label}: ${video.title}`,
            orderable_type: video.route,
            orderable_id: orderable_id,
            options: options,
            channel_id: video.channel.id,
        }

        if(voucher) {
            data.voucher_id = voucher.id
            data.discounted_price = voucher.discounted_price
        }

        Request.fromApi('ecommerce')
            .withErrors(setErrors)
            .post('/orders', data)
            .then((response: any) => {
                if (response.data.url) {
                    setPaying(true)
                    Functions.redirect(response.data.url)
                }
            })
    }

    function submitVoucher() {
        Request.fromApi('ecommerce')
            .withErrors(setErrors)
            .post(`/vouchers/${voucherCode}/validate`, {
                external_id: orderable_id,
                external_type: video.route,
            })
            .then((response: any) => {
                if (response.data?.id) {
                    setVoucher(response.data)
                }
            })
    }

    if (paying) {
        return <Components.Buttons.Button className={'w-full'} type={'primary'} onClick={() => {
            Functions.redirect(router.query.parent || Functions.pageUrl())
        }}>
            Betaald? Klik hier
        </Components.Buttons.Button>
    }

    function showPrice() {
        const price = video?.price?.amount ? Functions.formatPrice(video.price.amount, 'EUR', 'nl-NL') : 0


        if (!voucher) {
            return price
        }

        const discountedPrice = Functions.formatPrice(voucher.discounted_price, 'EUR', 'nl-NL')

        return <>
            <span style={{textDecoration: 'line-through'}}>{price}</span>
            <span className={'text-green-500'}>&nbsp;{discountedPrice}</span>
        </>
    }

    // const validateVoucher = voucher.data.hash && !order.data.voucher_id

    return <>
        <Components.Texts.Heading
            className={'text-center mb-10'}
            type={'h6'}
        >
            Kijk live voor {showPrice()}
        </Components.Texts.Heading>

        {/*{*/}
        {/*    !simple && <Components.Buttons.Button onClick={send}>*/}
        {/*        {`Dagticket ${price}`}*/}
        {/*        Betaal jouw club voor toegang.*/}
        {/*        Heb je een voucher? Klik dan op de link die je hebt ontvangen.*/}
        {/*        <ElementIcon type={'ticket'} className={'color-pink-200'}/>*/}
        {/*    </Components.Buttons.Button>*/}
        {/*}*/}

        {
            video.has_event && <Components.Forms.Field>
                <Components.Texts.Primary className={'text-gray-500'}>
                    Heb je een promo code? Vul hem hier in
                </Components.Texts.Primary>

                <Components.Forms.Input
                    type={'text'}
                    placeholder={'PROMO CODE'}
                    onChange={(voucher: string) => setVoucherCode(voucher)}
                    value={voucherCode}
                />
            </Components.Forms.Field>
        }

        <Components.Forms.Buttons
            errors={errors}
            submit={voucherCode && !voucher ? submitVoucher : submitOrder}
            loading={false}
            type={'wide'}
            title={voucherCode && !voucher ? 'Valideren' : 'Betalen'}
        />
    </>
}