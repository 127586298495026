import React, {useState} from 'react'
import {Components, Hooks, Functions} from "eyecons-components";
import {useRouter} from "next/router";
import {Request} from 'eyecons-requests'

export type Pages = 'login' | 'register' | 'password-reset' | 'profile'
export type SetPage = (page: { page: Pages, locale: string }) => void

type LoginFormProps = {
    setPage?: SetPage
    onLogin?: () => void
}

export function LoginForm({setPage, onLogin}: LoginFormProps) {
    const [errors, setErrors] = useState([])
    const router = useRouter()
    const [Locale, locale] = Hooks.useLocale(router.query.locale || 'nl')
    const [login, setLogin] = useState<any>({
        email: '',
        password: '',
        locale
    })


    function submitLogin() {
        Request.fromApi('auth').withErrors(setErrors).post('/login', login).then((response: any) => {
            if (response.data) {
                if (onLogin) {
                    onLogin()
                    return
                }

                // TODO do redirects fully from here, not backend
                if (router.query.return) {
                    return router.replace(router.query.return as string)
                }

                if (response.redirect) {
                    return router.replace(response.redirect)
                }
            }

            // window.location.href = response.data.channel.id ? `${process.env.NEXT_PUBLIC_STORYBOOK_DASHBOARD_V2_URL}/videos` : '/'
        })
    }

    return <>
        <div className={'relative text-center mb-4'}>
            <Locale/>
            <Components.Texts.Heading type={'h5'} appendClassname={'text-dark'}>
                {Functions.translate('Login', locale)}
            </Components.Texts.Heading>
        </div>

        <Components.Texts.Primary appendClassname={'mb-2 text-center'}>
            {Functions.translate('No account?', locale)}
            {' '}
            {
                setPage && <button className={'text-blue-200'} onClick={() => setPage({
                    page: 'register',
                    locale
                })}>{Functions.translate('Register')}</button>
            }
            {
                !setPage && <Components.Texts.Link href={'/register'}>
                    {Functions.translate('Register')}
                </Components.Texts.Link>
            }
        </Components.Texts.Primary>

        <Components.Forms.Field>
            <Components.Forms.Input
                placeholder={Functions.translate('E-mail address', locale)}
                type={'email'}
                onChange={(email: string) => setLogin({...login, email})}
                value={login.email}
            />
        </Components.Forms.Field>

        <Components.Forms.Field>
            <Components.Forms.Input
                className={'mb-2'}
                type={'password'}
                placeholder={Functions.translate('Password', locale)}
                onChange={(password: string) => setLogin({...login, password})}
                value={login.password}
            />

            <div className={'text-right'}>
                {
                    setPage && <button className={'text-blue-200 text-xs'} onClick={() => setPage({
                        page: 'password-reset',
                        locale
                    })}>
                        {Functions.translate('Forgot password?', locale)}
                    </button>
                }

                {
                    !setPage && <Components.Texts.Link className={'text-xs'} href={`/password-forgot?locale=${locale}`}>
                        {Functions.translate('Forgot password?', locale)}
                    </Components.Texts.Link>
                }
            </div>
        </Components.Forms.Field>

        <Components.Forms.Buttons
            type={'wide'}
            errors={errors}
            title={Functions.translate('Login', locale)}
            submit={submitLogin}
        />
    </>
}