import React, {useState, useEffect} from 'react'
import {Components, Functions} from 'eyecons-components'
import {Request} from 'eyecons-requests'

function isValidDate(dateString: string) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
}

export function ProfileForm({locale}: {locale: string}) {
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(true)
    const [advertising, setAdvertising] = useState(false)
    const [user, setUser] = useState<any>({
        name: '',
        birthday: '',
        options: {
            advertising_disabled: false
        }
    })

    useEffect(() => {
        const update = JSON.parse(localStorage.getItem('user') || '{}')
        setUser({...user, ...update})
        setLoading(false)
        setAdvertising(Functions.verifyAge(update))
    }, [])


    function submit() {
        Request.fromApi('auth').withErrors(setErrors).put('/api/user', user).then((response: any) => {
            if (response.data) {
                localStorage.removeItem('user')
                setSuccess(true)
            }
        })
    }

    if (success) {
        return (
            <div className={'relative text-center'}>
                <Components.Texts.Heading type={'h5'} appendClassname={'text-dark mb-4'}>
                    {Functions.translate('Check your mailbox', locale)}
                </Components.Texts.Heading>

                <Components.Texts.Primary className={'text-gray-500'}>
                    {Functions.translate('We have just sent you an email to confirm your email address. Click on the link in the email to set a password and you are good to go!', locale)}
                </Components.Texts.Primary>
            </div>
        )
    }

    if (loading) {
        return null
    }

    return <>
        <div className={'text-center mb-4'}>
            <Components.Texts.Heading type={'h5'} appendClassname={'mb-4 text-dark'}>
                {Functions.translate('One last question..', locale)}
            </Components.Texts.Heading>

            <Components.Texts.Primary className={'text-disabled'}>
                {Functions.translate('To comply with the legal guidelines of gambling advertisements, we ask you to fill in some information.', locale)}
            </Components.Texts.Primary>
        </div>

        <Components.Forms.Field>
            <Components.Forms.Label htmlFor={'name'}>{Functions.translate('Name', locale)}</Components.Forms.Label>
            <Components.Forms.Input
                id={'name'}
                placeholder={Functions.translate('E.g.', locale) + ' Jan'}
                onChange={(name: string) => setUser({...user, name})}
                value={user.name}
            />
        </Components.Forms.Field>

        <Components.Forms.Field>
            <Components.Users.FormBirthday
                withLabel
                value={user.birthday}
                onChange={(birthday: string) => {
                    setUser({
                        ...user,
                        birthday,
                        options: {
                            ...user.options,
                            advertising_disabled: !Functions.verifyAge({birthday})
                        }
                    })

                    if (isValidDate(birthday)) {
                        setAdvertising(Functions.verifyAge({birthday}))
                    }
                }}
            />
        </Components.Forms.Field>

        {
            advertising && <Components.Forms.Field>
                <Components.Forms.Checkbox
                    id={'advertising_disabled'}
                    checked={user.options.advertising_disabled}
                    onChange={() => setUser({
                        ...user,
                        options: {
                            ...user.options,
                            advertising_disabled: !user.options.advertising_disabled
                        }
                    })}
                >
                    {Functions.translate('I choose an experience without online gambling advertisements.', locale)}
                </Components.Forms.Checkbox>
            </Components.Forms.Field>
        }

        <Components.Forms.Buttons
            icon={'chevron-right'}
            icon_position={'right'}
            type={'wide'}
            className={'mb-4'}
            errors={errors}
            title={`${Functions.translate('Update profile', locale)} (2/2)`}
            submit={submit}
        />

        <Components.Texts.Primary className={'text-center text-disabled'}>
            {Functions.translate('The age must be entered truthfully.', locale)}
        </Components.Texts.Primary>
    </>
}